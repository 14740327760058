<template>
    <div style="text-align: justify; padding-right: 10px">
        <h5 style="text-align: center">
            <strong>SOFTWARE AS A SERVICE (SAAS) TERMS AND CONDITIONS </strong>
        </h5>
        <div>
            <p>
                <strong
                    >These Software as a Service (SaaS) and Services Terms and
                    Conditions (“Terms”</strong
                >
                or this <strong>“Agreement”</strong>) govern the purchase of the
                license to the Software, the access to Services and the use of
                the Website by the Client and its Users (<strong>“you”</strong>,
                <strong>“your”</strong>, and terms of similar meaning) made
                available by Bildhive Technologies Inc. (<strong>“we”</strong>,
                <strong>“us”</strong>, <strong>“Provider”</strong> and terms of
                similar meaning) and its suppliers, made pursuant to an Order
                Form (as defined below).
            </p>
            <p>
                By accepting this Agreement, by (a) clicking a box indicating
                acceptance, (b) accepting or executing an Order Form that
                references this Agreement, or (c) otherwise accessing or using
                the Services or the Website, you agree to be bound by these
                Terms, and all terms, policies and guidelines incorporated by
                reference in these Terms.
            </p>
            <p>
                If the individual accepting this Agreement is accepting on
                behalf of a company or legal entity, such individual represents
                that they have the authority to bind such entity and its
                affiliates to these Terms, in which case the term “Client” or
                “User” (as the case may be) shall refer to such entity and its
                affiliates. If the individual accepting this Agreement does not
                have such authority, or does not agree with these Terms, such
                individual must not accept this Agreement and may not use the
                Services or the Website.
            </p>
            <p>
                Order Forms are automatically deemed to include all of the terms
                and conditions of these Terms; provided that whenever the
                provisions of the Order Form expressly conflict with these
                Terms, the conflicting provisions of the Order Form control and
                shall take precedence over the conflicting provisions of the
                Terms.
            </p>
            <p><strong>1. DEFINITIONS</strong></p>
            <p>
                1.1 <strong>“Administrator”</strong> means a person or persons
                assigned by the Client to have the authority to act as the
                administrator of the subscription on behalf of the Client.
            </p>
            <p>
                1.2 <strong>“Anti-Spam Laws”</strong> means any applicable
                federal, provincial and local laws, regulations and rules
                governing the sending of commercial electronic messages.
            </p>
            <p>
                1.3 <strong>“Administrator”</strong> means a person or persons
                assigned by the Client to have the authority to act as the
                administrator of the subscription on behalf of the Client.
            </p>
            <p>
                1.4 <strong>“Business Day”</strong> means any day except
                Saturday, Sunday or any day on which banks are generally not
                open for business in the City of Toronto, Ontario.
            </p>
            <p>
                1.5 <strong>“Client”</strong> shall mean the individual or
                organization who is bound by the terms of an Order Form and
                these Terms and Conditions.
            </p>
            <p>
                1.6 <strong>“Client Data”</strong> means any data or content
                inputted into the Software by the Client or any of its employees
                or Users and hosted on the servers of the Cloud Provider,
                including, but not limited to Client Marks and User Content.
            </p>
            <p>
                1.7 <strong>“Client Marks”</strong> means the Client’s
                trademarks (registered and unregistered), trade names, designs,
                logos and other indicia of origin that are uploaded by the
                Client or its Users onto the Software or Website.
            </p>
            <p>
                1.8 <strong>“Cloud Provider”</strong> has the meaning given in
                Section 6.1.
            </p>
            <p>
                1.9 <strong>“Fees”</strong> means the fees to be paid by the
                Client pursuant to an Order Form, these Terms and any applicable
                Schedules.
            </p>
            <p>
                1.10 <strong>“Order Form”</strong> means (i) the Bildhive
                Platform as a Service (SaaS) Order Form; (ii) an online order
                through the Website specifying the Services to be provided
                hereunder, that is entered into between the Client and the
                Provider, including any Schedules, addenda and supplements
                thereto.
            </p>
            <p>
                1.11 <strong>“Personal Information”</strong> means any
                information relating to identifiable individuals, the
                collection, use or disclosure of which is regulated by Privacy
                Laws.
            </p>
            <p>
                1.12 <strong>“Privacy Laws”</strong> means any applicable
                federal, provincial and local laws, regulations and rules
                governing the collection, use and disclosure of information
                relating to identifiable individuals, including the Personal
                Information Protection and Electronic Documents Act (Canada),
                the Personal Information Protection Act (British Columbia), the
                Personal Information Protection Act (Alberta), and any similar
                appliable legislation.
            </p>
            <p>
                1.13 <strong>“Provider Content”</strong> means any data or
                content created by and inputted into the Software by the
                Provider or any of its representatives, including, but not
                limited to templates, themes, layouts, and designs.
            </p>
            <p>
                1.14 <strong>“Schedule”</strong> means a schedule, which is
                attached to this Agreement or which may be added hereafter by
                written agreement of the parties.
            </p>
            <p>
                1.15 <strong>“Services”</strong> means the use of the Software
                and other related services to be provided by the Provider to the
                Client pursuant to the Order Form.
            </p>
            <p>
                1.16 <strong>“Software”</strong> means the Bildhive automated
                workflow platform, including Provider Content, as a service
                licensed to the Client and the number of Project licenses
                purchased by the Client pursuant to the terms of the Order Form.
            </p>
            <p>
                1.17 <strong>“Term”</strong> shall have the meaning given in
                Section 12.1.
            </p>
            <p>
                1.18 <strong>“Third Party Content”</strong> means any data owned
                or licensed by a third party and inputted into the Software,
                including, but not limited to information derived from third
                party or social media user accounts and maps provided by Google
                Maps.
            </p>
            <p>
                1.19 <strong>“User”</strong> means an individual user who (i)
                accesses the Website and/or is permitted to use the Software;
                and (ii) agreed to these Terms. Users may include employees or
                contractors of the Client and other individuals or entities who
                are authorized by the Client to use the Services under the
                Client’s project account.
            </p>
            <p>
                1.20 <strong>“User Content”</strong> means any data or content
                inputted into the Software or Website, or generated within the
                Software by, a User, except for Client Data.
            </p>
            <p>
                1.21 <strong>“Website”</strong> means Bildhive’s website made
                available
                <a href="https://bildhive.com/" target="_">www.bildhive.com</a>
                or its sub domains or domains with identical names under other
                top domains and owned by Provider.
            </p>

            <p><strong>2. BILDHIVE SOFTWARE</strong></p>
            <p>
                The Software is a new home real estate sales and marketing
                platform that provides home builders, real estate brokers,
                agents, and marketing firms with a series of integrated
                applications that help them sell and market new home real estate
                pre-construction development projects. While the Software can
                support already existing real estate projects, it is ideally
                suited for new home builders, real estate brokerages and
                marketing agencies who need a unified and easy to use platform
                that allows them to market, manage, sell or lease real estate.
                Through the Software, Clients may create a project
                (<strong>“Project”</strong>) and use the applications available
                pursuant to an Order Form.
            </p>

            <p>
                <strong
                    >3. PRIVACY POLICY, SECURITY AND ADDITIONAL POLICIES</strong
                >
            </p>
            <p>
                3.1 Privacy Policy and Security. Please refer to the privacy
                policy by clicking
                <a :href="redirectTo" target="_">here</a>
                (the <strong>“Privacy Policy”</strong>) for information on how
                we or our licensors collect, use and disclose your Personal
                Information. By using the Services and the Website, you agree to
                the use, collection and disclosure of personally identifiable
                information in accordance with the Privacy Policy.
            </p>
            <p>
                3.2 Additional Policies. In addition, by using the Software, you
                agree to be bound by any additional policies, guidelines, rules,
                obligations, agreements or documents communicated through the
                Software, including, without limitation, as applicable, any
                policies referred to in these Terms. For example, we may post
                additional policies, guidelines, rules, obligations or
                agreements that specifically relate to your use of the Software.
                You agree to comply with such policies, guidelines, rules,
                obligations or agreements even if they are not identified in
                these Terms.
            </p>

            <p><strong>4. USER ACCOUNTS</strong></p>
            <p>
                4.1 Provisioning and User Accounts. Upon agreeing to an Order
                Form, the initial Administrator of the Client will be permitted
                to register for a User account. The initial Administrator may
                add other Administrators and authorize Users subject to the
                limitations and additional terms described in the Order Form.
                The Initial Administrator and other Administrators shall be
                deemed to have the authority to manage (including adding and
                removing) Users. Administrators may deactivate any User if the
                Administrator wishes to terminate access to the Service for any
                User. Access to specific features of the Services are only be
                available to specific user types.
            </p>
            <p>
                4.2 Registration. Upon logging into the Software for the first
                time, the Administrators and Users will be prompted to register
                for a User account. Administrators and Users agree to: (a)
                provide accurate, current and complete information as may be
                prompted by any registration forms on the Software or the
                Website (<strong>“Registration Data”</strong>); (b) maintain the
                security of the their password; (c) maintain and promptly update
                the Registration Data, and any other information they provide to
                the Software or the Website, and to keep it accurate, current
                and complete; and (d) accept all risks of unauthorized access to
                the Registration Data and any other information provided to
                Provider. The Client shall be responsible for all activity by
                Users on the Software, including the activity performed on the
                Software through the User accounts by an agent, representative,
                employee (including former employees who maintained access to
                the Services), or any other person acting on behalf of such
                User. It is the responsibility of the Client to delete User
                accounts or otherwise remove access to Users who should no
                longer be active (e.g., a User who is no longer an employee or
                contractor of the Client or a Vendor of the Client).
            </p>

            <p>
                <strong
                    >5. LICENSE TO SOFTWARE, WEBSITE AND LICENSE
                    RESTRICTIONS</strong
                >
            </p>
            <p>
                5.1 License to Software and Website. Provider hereby grants to
                Client and authorized Users a non-exclusive, non-transferable
                license to use the Software and the Website and solely permit
                the Client and Users to use the Website and the functionality
                contained within the Software for legitimate purposes during the
                Term and subject to the applicable Order Form.
            </p>
            <p>
                5.2 License Restrictions. Except as set forth in this Agreement,
                the Order Form, any Schedule and to the extent contrary by
                Applicable Law: the Client and User may not (a) make or
                distribute copies of the Software or Website; (b) alter, merge,
                adapt or translate the Software or Website, or decompile,
                reverse engineer, disassemble, or otherwise reduce the Software
                or Website to a human-perceivable form; (c) sell, rent, lease,
                host or sublicense the Software (except as is incidental or
                necessary for the provision of the Software to Users); (d)
                modify the Software or Website or create derivative works based
                upon the Software or Website; provided however that the
                foregoing will not restrict Client’s rights to exploit any
                Client Data which may be incorporated into, reside in, or form a
                part of the Software; (e) use the Services or the Website in a
                manner that breaches the rights of any third party, any contract
                or legal duty or violate any Applicable Law; (f) copy the
                Services or Website or any part, feature, function or user
                interface thereof; (g) access or use the Services or Website in
                order to build a competitive product or service; (h) use the
                Services or Website other than for its intended purposes; and/or
                (i) do anything to interfere with or impair the intended
                operation of the Website.
            </p>

            <p>
                <strong>6. HOSTING AND SUPPORT </strong>
            </p>
            <p>
                6.1 Hosting. The Provider will cause the Software to be hosted
                on a cloud server maintained by a reputable third party provider
                (<strong>“Cloud Provider”</strong>). The Provider will be
                responsible for contracting with the Cloud Provider, and for
                paying all fees and charges of the Cloud Provider. All Client
                Data stored on the Services is located on servers operated by
                the Cloud Provider in Canada and/or the United States.
            </p>
            <p>
                6.2 Support, Training. Support, maintenance and training
                Services are provided to the Client in accordance with the terms
                of the Provider’s Service Level Agreement.
            </p>
            <p>
                6.3 Backups. The Provider will create a backup or cause its
                Cloud Provider to create a backup of the Software (including all
                Client Data) no less frequently than once every twenty-four (24)
                hours. Upon request from the Client, the Provider will provide
                the Client with a copy of the most recent Client Data available.
            </p>

            <p>
                <strong
                    >7. CLIENT REPRESENTATIONS AND WARRANTIES; CLIENT
                    RESPONSIBILITIES</strong
                >
            </p>
            <p>
                7.1 Client Representations and Warranties. The Client represents
                and warrants that the Client’s use of the Services or Website
                and the use of the Services or Website by the Client’s Users
                will (a) be consistent with this Agreement and any licenses
                provided; and (b) comply with Applicable Law, including Privacy
                Laws and Anti-Spam Laws. The Client represents and warrants that
                he/she/it maintains all necessary permissions and has acquired
                consents (where required) to lawfully send electronic
                communications to third parties. The Client shall be liable for
                the acts and omissions of any of its Users, directors, officers,
                employees, contractors, representatives or agents as if such act
                or omission were an act or omission of the Client.
            </p>
            <p>
                7.2 Additional Responsibilities. The Client agrees (a) that it
                shall be responsible for providing and maintaining its own
                Internet access with the necessary bandwidth speeds as
                recommended by the Provider and all necessary telecommunications
                equipment, services, software and other materials (collectively,
                <strong>“Client Equipment”</strong>) at the Client’s location(s)
                necessary for accessing the Services; (b) the Client represents
                and warrants that it has the right to enter into this Agreement
                and to allow the Provider to perform the Services; (c) the
                Client is solely responsible for providing, updating, uploading,
                modifying and maintaining the Client Data; and (d) the Client
                acknowledges and agrees that the Software is not a marketplace
                and any contract of sale made through the Software is directly
                between the Client and his/her/its customer.
            </p>
            <p>
                7.3 Client Indemnity Regarding Use of Services and Website and
                Client Data. The Client shall be solely responsible for all
                inputs, selection and use of the Services and Website and all
                Client Data or other data transmitted, received or created using
                the Services or Website, even if transmitted, received or
                created by someone else. The Client agrees to defend, indemnify
                and hold the Provider, its directors, officers, employees,
                agents, contractors and affiliates harmless from any loss,
                damage or liability which may result therefrom or from any
                breach by the Client or its Users of this Agreement.
            </p>
            <p>
                7.4 Acceptable Use of the Services and Website. The Client and
                its Users may not: (a) use, or encourage, promote, facilitate or
                instruct others to use the Services or the Website for any
                illegal, harmful or offensive use or to transmit, store,
                display, distribute or otherwise make available content that is
                illegal, harmful or offensive; (b) use the Services or the
                Website to violate the security or integrity of any network,
                computer or communications system, software application, or
                network or computing device; (c) make network connections to any
                users, hosts, or networks unless the Client has permission to
                communicate with them; and/or (d) distribute, publish, send or
                facilitate the sending of unsolicited mass e-mail or other
                messages, promotions, advertising or solicitations (like
                ‘spam’), including commercial advertising and informational
                announcements. The Provider reserves the right, but does not
                assume the obligation, to investigate any violation of this
                Section or misuse of the Services or Website.
            </p>
            <p>
                7.5 Sensitive Personal Information. The Client agrees that it
                will not use the Service to store or transfer the following
                Personal Information deemed “sensitive” or “special” under
                Applicable Law: health information, biometric or genetic
                information, Personal Information collected from minors,
                geo-location information of individuals, or information about an
                individuals’ racial or ethnic origin, trade union membership,
                sex life or sexual orientation, political opinions, or religious
                or philosophical beliefs.
            </p>

            <p id="payment">
                <strong>8. FEES, PAYMENTS AND SUSPENSION OF SERVICE</strong>
            </p>
            <p>
                8.1 Fees. In consideration for the Services described herein,
                the Client shall pay to the Provider, in advance, the Fees more
                particularly described on the Website, the Order Form, and any
                applicable Schedules, subject to identified usage limitations.
                Except as otherwise specified herein, on the Website or the
                Order Form, (i) the Fees are based on the Services purchased and
                not actual usage, (ii) payment obligations are non-cancelable
                and (iii) a subscription plan cannot be decreased during the
                relevant subscription term.
            </p>
            <p>
                8.2 Additional Fees. If the Client exceeds its subscription plan
                usage limits (by exceeding their monthly email sends), the
                Client will incur additional Fees for the prior billing period
                in which they exceeded their email send limits. The additional
                Fees will be notified to the Client as soon as the subscription
                plan is exceeded and will also be detailed in the Client account
                and on their invoice along with their monthly Fees. If the
                Client pays their Fees annually and they exceed their email send
                limits during a particular calendar month, the Client will be
                charged for the additional email sends in the calendar month in
                which they were exceeded.
            </p>
            <p>
                8.3 Change to Fees. The Provider reserves the right to introduce
                or change any Fees on or related to the Services from time to
                time, upon providing you with twenty (20) days advanced written
                notice, by e-mailing the Administrator(s) of the Client. If the
                Client does not accept the changed or new Fee, the Client must
                terminate these Terms or amend its Services prior to the end of
                the twenty (20) day period. After such period, if the Terms are
                not terminated or the Services are not amended, the Client or
                any of its authorized Users continued use or purchase of the
                Services after the effective date of the change indicates the
                Client’s agreement with the new or changed Fees. For clarity,
                any changes to the Fees only apply to future monthly or annual
                subscriptions.
            </p>
            <p>
                8.4 Upgrading the Services. The Client acknowledges that the
                Provider will not automatically upgrade or downgrade a Client
                subscription plan despite the Client’s usage. The Client may
                elect to upgrade their subscription plan through the Software to
                account for increased email sends.
            </p>
            <p>
                8.5 Client Payment Information. The Client is responsible for
                providing complete and accurate billing information to the
                Provider. The Client agrees that the Provider may update the
                Client’s payment information through the use of account
                refresher services provided by third party payment processors.
            </p>
            <p>
                8.6 Recurring Payments. The Client agrees that once he/she/it
                chooses a subscription plan, such subscription plan shall
                automatically renew on an annual or monthly basis depending on
                the subscription plan (the <strong>“Renewal Date”</strong>). The
                Client authorizes Bildhive to automatically charge the Client
                for the applicable Fees on or after the Renewal Date unless the
                subscription plan has been terminated or cancelled in accordance
                with these Terms. The Client must cancel its subscription plan
                prior to the Renewal Date in order to avoid billing of the next
                period’s Fees. If the Client chooses to cancel its subscription
                plan during the Term, the Client may use the Software until the
                end of the Client’s then-current Term, but will not be issued a
                refund for the most recently (or any previously) charged Fees.
            </p>
            <p>
                8.7 Taxes. The Client shall be responsible for all applicable
                sales, goods and services, harmonized sales, value added, use,
                excise, other similar taxes, levies and charges not otherwise
                included in the Fees imposed by applicable tax authorities on
                the provision of Services hereunder. The Client shall pay to the
                Provider such taxes, levies and charges which the Provider is
                registered to charge and collect.
            </p>
            <p>
                8.8 Suspension of Service and Acceleration. If any charge owing
                by the Client under these Terms or any other agreement is ten
                (10) days or more overdue, the Provider may, without limiting
                its other rights and remedies, accelerate the Client’s unpaid
                fee obligations under such agreements, so that all such
                obligations become immediately due and payable, and suspend the
                Services until such amounts are paid in full.
            </p>

            <p>
                <strong
                    >9. INTELLECTUAL PROPERTY, CLIENT DATA AND PRIVACY</strong
                >
            </p>
            <p>
                9.1 Ownership of the Software and Website. Except for any grant
                of licenses in this Agreement or as otherwise expressly provided
                in this Agreement, the Provider and its licensors, as
                applicable, shall retain all copyright, patent rights, trade
                secret rights, trademarks and other proprietary rights or
                interests (<strong>“Intellectual Property Rights”</strong>) in
                the Software and Website. Nothing in this Agreement, the Order
                Form, or any Schedules shall be deemed to convey to the Client
                or any other party, any ownership right, in or to Software and
                Website.
            </p>
            <p>
                9.2 Ownership of Client Data. The Provider acknowledges and
                agrees that, as between the Parties, the Client is the sole and
                exclusive owner of the Client Data, and that no right or
                interest in the Client Data, other than pursuant to Section 9.3
                of this Agreement, and will be collected, handled and used by
                the Provider only in compliance with the terms of this
                Agreement.
            </p>
            <p>
                9.3 License from Client to Provider. The Client hereby grants to
                the Provider a non-exclusive, royalty-free, non-transferable,
                limited right to use during the Term, Client Data provided to
                the Provider solely to perform Services pursuant to this
                Agreement.
            </p>
            <p>
                9.4 All Other Rights Reserved, Further Assurances. Except as
                expressly set forth herein or in the Order Form or a Schedule,
                all Intellectual Property Rights are expressly reserved by the
                parties. The Client or the Provider, as applicable, shall
                execute and deliver such instruments and take such other steps
                as may be requested by the Provider or the Client, as
                applicable, from time to time in order to give effect to the
                provisions of this Article.
            </p>
            <p>
                9.5 Privacy Laws. The Client and authorized Users represent that
                (a) they have complied with all applicable Privacy Laws in
                connection with the collection, use and disclosure of Personal
                Information, and the provision of Personal Information to the
                Provider complies with all applicable Privacy Laws; (b) all
                individuals to whom such Personal Information relates have
                consented to the Provider’s collection, use and disclosure of
                such Personal Information for the purposes disclosed in this
                Agreement or our Privacy Policy; (c) they shall ensure that any
                Personal Information provided to the Provider is accurate,
                complete and up-to-date; and (d) if and to the extent that the
                Client or any of its authorized Users input Personal Information
                into the Software, such Client shall be deemed to maintain
                custody of such Personal Information for the purposes of the
                applicable Privacy Laws, and shall be subject to all of the
                duties and obligations in respect of such Personal Information
                under applicable Privacy Laws.
            </p>
            <p>
                9.6 Third Party Content and Software. The Software may contain
                or provide the ability to integrate the Service with Third Party
                Content and/or third party software. The Client and all
                authorized Users agree that the Provider has no liability
                arising from use of any integrations or arising from such Third
                Party Content, third party software, and related services. The
                Provider may modify or cancel the integrations at any time,
                without notice.
            </p>
            <p>
                9.7 License by Client to Use Feedback. The Client grants the
                Provider a worldwide, perpetual, irrevocable, royalty-free
                license to use and incorporate into its services any suggestion,
                enhancement request, recommendation, correction or other
                feedback provided by the Client or Users relating to the
                operation of the Software, the Services, or the Website.
            </p>

            <p>
                <strong>10. DISCLAIMER AND LIMITATION OF LIABILITY</strong>
            </p>
            <p>
                10.1 Disclaimer. Except as set out in the Order Form or this
                Agreement, the Services and the Website are provided to the
                Client and authorized Users on an “as is” basis, without
                warranties from the Provider of any kind, either express or
                implied. The Provider expressly disclaims all other warranties,
                express or implied, including, without limitation implied
                warranties of merchantability, fitness for a particular purpose,
                title and non-infringement, unless otherwise specified in the
                applicable Order Form. The Provider disclaims all liability for
                any content posted on the Website that may be outdated,
                inaccurate or misleading. The Provider does not warrant that the
                Services or the Website will be error-free or will operate
                without interruption.
            </p>
            <p>
                10.2 No Indirect, Etc. Damages. Under no circumstances shall
                either party be liable to the other party for any claim for (i)
                indirect, special or consequential damages, (ii) compensation
                for loss of profits, anticipated revenue, savings or goodwill,
                or (iii) exemplary, aggravated or punitive damages howsoever
                incurred; in each case under any theory of law or equity,
                arising out of or in any way related to this Agreement or any
                Services, even if advised of the possibility thereof.
            </p>
            <p>
                10.3 Limitation of Aggregate Liability. Except as otherwise
                specifically provided under this Agreement, the liability of
                either party for any claim, demand or cause of action whether
                based on contract, tort (including negligence) or otherwise, or
                for any losses, damages, costs and expense (including but not
                limited to legal fees) (collectively, <strong>“Losses”</strong>)
                arising out of or resulting from this Agreement shall not exceed
                the Fees paid or payable by the Client to the Provider under
                this Agreement in the twelve (12) months preceding the Loss.
            </p>
            <p>
                10.4 Reasonableness of Limitations. The Provider, the Client and
                Users agree that the limitations contained in this Section 10
                are reasonable in scope and form an integral part of this
                Agreement.
            </p>

            <p>
                <strong>11. INDEMNIFICATION</strong>
            </p>
            <p>
                11.1 Indemnity by Client. The Client agrees to defend, indemnify
                and hold the Provider, its directors, officers, employees,
                agents, contractors and affiliates, harmless from any loss,
                damage or liability, including all reasonable legal costs, that
                the Provider may incur as a result of or in connection with any
                third party claim relating to or resulting from (a) any breach
                by the Client of the Client’s obligations under this Agreement,
                including its obligation to comply with all Applicable Law; or
                (b) any third party claim that the Client’s use of any Client
                Data or Third Party Content infringes, misappropriates or
                otherwise violates the intellectual property rights of any third
                party.
            </p>
            <p>
                11.2 Indemnity by Provider. The Provider agrees to defend,
                indemnify and hold the Client, its directors, officers,
                employees, agents, contractors and affiliates, harmless from any
                loss, damage or liability, including all reasonable legal costs,
                that the Client may incur as a result of or in connection with:
                (a) any valid claim that the Software or any portion of it
                infringes the intellectual property rights of any third party;
                (b) any third-party claim relating to or resulting from any
                breach by the Provider of the Provider’s obligations under this
                Agreement, including its obligation to comply with all
                Applicable Law.
            </p>

            <p>
                <strong>12. TERM</strong>
            </p>
            <p>
                12.1 Term. The term of this Agreement (<strong>“Term”</strong>)
                shall commence on the Effective Date or date of acceptance of
                these Terms set out in the Order Form, and will continue for the
                length of time described in the Order Form as the Initial Term
                (<strong>“Initial Term”</strong>). Thereafter, this Agreement
                will automatically renew for successive terms equal to the
                length of time of the Initial Term (<strong
                    >“Renewal Terms”</strong
                >), unless terminated in accordance with this Agreement.
            </p>
            <p>
                12.2 Termination. (a) The Client may terminate this Agreement by
                canceling his/her/its subscription plan at any time by accessing
                the Software, going to his/her/its account settings and
                following the instructions provided. (b) Breach. Either party
                may terminate this Agreement if the other party materially
                breaches this Agreement, including any failure to make payments
                when due, and such other party fails to cure such breach in all
                material respects within thirty (30) days after being given
                notice of the breach from the non-breaching party. (c)
                Insolvency. Either party may terminate this Agreement, upon
                written notice to the other party, if such other party is
                subject to proceedings in bankruptcy or insolvency, voluntarily
                or involuntarily, if a receiver is appointed with or without the
                other party’s consent, if the other party assigns its property
                to its creditors or performs any other act of bankruptcy, or if
                the other party becomes insolvent and cannot pay its debts when
                they are due.
            </p>
            <p>
                12.3 Early Termination. If this Agreement is terminated pursuant
                to Section 12.2 prior to the end of such current term, the
                Client shall pay to the Provider, as liquidated damages and not
                a penalty, an amount equal to the total monthly Fees (as
                described in the Order Form) multiplied by the number of months
                remaining prior to the end of such current term.
            </p>
            <p>
                12.4 Termination and Suspension of Users. Notwithstanding any
                provision of these Terms, the Provider reserves the right, in
                its sole discretion, without any notice or liability to the
                Client or any User, to (a) terminate a User’s license to use the
                Software, or any portion thereof; (b) block or prevent a User’s
                future access to and use of all or any portion of the Software
                or the Website; (c) change, suspend, or discontinue any aspect
                of the Software or the Website; and (d) impose limits on the
                Software or Website.
            </p>
            <p>
                12.5 Effect of Termination. If this Agreement is terminated in
                accordance with Section 12.2, then: (a) the Provider may
                immediately deactivate the Client’s account and suspend access
                to the Software; (b) the Client may download all Client Data
                inputted into the Software; (c) upon a request by the Client
                within thirty (30) days of termination, the Provider will,
                within fifteen (15) days of such request, provide to the Client
                a copy of the Client Data in a format that is readable using
                commercially available third party software and or the Software;
                (d) upon a request by the Client within thirty (30) days of
                termination, the Provider will delete and cause to be deleted
                all Client Data from all computer systems owned and controlled
                by the Provider, and (e) if not requested by the Client within
                thirty (30) days of termination, the Provider will have no
                obligation to retain Client Data and shall be entitled to,
                unless prohibited by Applicable Laws, delete all Client Data
                from all computer systems owned and controlled by the Provider.
            </p>

            <p>
                <strong>13. CONFIDENTIALITY</strong>
            </p>
            <p>
                13.1 Definition of Confidential Information.
                <strong>“Confidential Information”</strong> means all
                information disclosed by a party (<strong
                    >“Disclosing Party”</strong
                >) to the other party (<strong>“Receiving Party”</strong>),
                whether orally or in writing, that is designated as confidential
                or that reasonably should be understood to be confidential given
                the nature of the information and the circumstances of
                disclosure. Client’s Confidential Information includes Client
                Data. The Provider’s Confidential Information includes the
                Software, the Services and the terms and conditions of this
                Agreement. However, Confidential Information does not include
                any information that (a) is or becomes generally known to the
                public without breach of any obligation owed to the Disclosing
                Party; (b) was known to the Receiving Party prior to its
                disclosure by the Disclosing Party without breach of any
                obligation owed to the Disclosing Party; (c) is received from a
                third party without breach of any obligation owed to the
                Disclosing Party; or (d) is independently developed by the
                Receiving Party.
            </p>
            <p>
                13.2 Protection of Confidential Information. The Receiving Party
                will (a) use the same degree of care that it uses to protect the
                confidentiality of its own confidential information of like kind
                (but not less than reasonable care); (b) not use any
                Confidential Information of the Disclosing Party for any purpose
                outside the scope of this Agreement; and (c) except as otherwise
                authorized by the Disclosing Party in writing, limit access to
                Confidential Information of the Disclosing Party to those of its
                and its affiliates’ employees and contractors who need that
                access for purposes consistent with this Agreement and who have
                signed confidentiality agreements with the Receiving Party
                containing protections no less stringent than those herein.
            </p>
            <p>
                13.3 Compelled Disclosure. The Receiving Party may disclose
                Confidential Information of the Disclosing Party to the extent
                compelled by law to do so, provided the Receiving Party, if
                permitted by law, gives the Disclosing Party prior notice of the
                compelled disclosure.
            </p>
            <p>
                13.4 Destruction. Each party, upon the request of the other
                party or within thirty (30) days after termination of this
                Agreement (whichever is earlier), agrees to return and cause its
                representatives to return, all copies of Confidential
                Information belonging to or provided by the other party or
                destroy such copies as directed by that party and certify their
                destruction.
            </p>
            <p>
                13.5 Indemnity. Each party agrees to indemnify and hold the
                other party harmless from and against all loss or damage or any
                kind and nature suffered by the other party as a result of any
                breach by it or its representatives of its obligations relating
                to confidentiality contained in this Section 13.
            </p>

            <p>
                <strong>14. GENERAL</strong>
            </p>
            <p>
                14.1 Governing Law. This Agreement shall be governed by and
                construed in accordance with the laws of the Province of Ontario
                and the laws of Canada applicable therein. This Agreement shall
                be treated, in all respects, as an Ontario contract.
            </p>
            <p>
                14.2 Survival. Any terms and conditions of this Agreement which
                by their nature extend beyond termination of this Agreement
                shall survive such termination. This includes, without
                limitation Section 9 (Intellectual Property, Client Data and
                Privacy) (but not section 9.3 (License from Client to
                Provider)), Section 10 (Disclaimer and Limitation of Liability),
                Section 11 (Indemnification), Section 12.5 (Effect of
                Termination), Section 13 (Confidentiality) and applicable
                provisions of Section 14 (General).
            </p>
            <p>
                14.3 Publicity and Promotion. The Provider shall be permitted to
                disclose the Client’s name and logo as part of their Website
                customer list and marketing materials. Specific details
                regarding the use of the Services shall remain confidential. The
                Client may revoke his/her/its consent by providing written
                notice to the Provider.
            </p>
            <p>
                14.4 Value-Added Resellers.Clients may acquire the Services
                through the Provider’s value-added resellers. Such value-added
                resellers are independent from the Provider and unilaterally
                determine their prices and terms. The Provider is not
                responsible for their actions, omissions, statements or
                offerings.
            </p>
            <p>
                14.5 Beta Services. From time to time, the Provider (i) may
                invite a Client to try products or features related to the
                Software that are not generally available to all Clients or (ii)
                may release products or features related to the Software that
                are identified as beta, trial, proof of concept, sandbox,
                preview, pilot, limited release, or by a similar designation
                (collectively,
                <strong>“Beta Services”</strong>). Beta Services may be provided
                for evaluation or testing purposes only, and, therefore, may
                contain bugs or errors, and may not be as reliable as other
                features of the Software. The Beta Services are provided as-is
                basis without warranties of any kind and may be used at the
                Client’s own risk. The Client’s use of such features may include
                additional rules or restrictions that the Provider may place on
                their use. The Provider may discontinue Beta Services at any
                time in its sole discretion. Beta Services are not for
                production use, not supported and not subject to availability or
                security obligations. The Provider will have no liability for
                any harm or damage arising out of or in connection with the use
                of Beta Services. For the avoidance of doubt, Beta Services are
                a part of the Software, and, therefore, are subject to this
                Terms.
            </p>
            <p>
                14.6 14.6 Dispute Resolution. (a) This Section 14.3 sets out the
                process (the <strong>“Dispute Resolution Process”</strong>) for
                resolving all disputes, issues, controversies, and/or claims
                arising out of or in connection with this Agreement, or in
                respect of any legal relationship associated with or derived
                from this Agreement (<strong>“Disputes”</strong>). (b) Either
                party may initiate the Dispute Resolution Process by sending a
                notice of a Dispute (a <strong>“Dispute Notice”</strong>) to the
                other party. Upon delivery of a Dispute Notice to either party,
                each party shall appoint a knowledgeable, responsible,
                non-lawyer, management representative to meet and negotiate in
                good faith with the representative of the other party in order
                to resolve the Dispute. (c) All Disputes that are not resolved
                within thirty (30) days following delivery of a Dispute Notice
                shall be arbitrated and finally resolved, with no right of
                appeal, even on questions of law, pursuant to the National
                Arbitration Rules of the ADR Institute of Canada, Inc. The place
                of arbitration shall be Toronto, Ontario, Canada. The language
                of the arbitration shall be English. (d) Notwithstanding
                anything contained in the Agreement to the contrary, either
                party shall be entitled to seek injunctive or other equitable
                relief from a court of competent jurisdiction whenever the facts
                or circumstances would permit a party to seek such relief.
            </p>
            <p>
                14.7 Relationship. The relationship between the Client and the
                Provider will at all times be one of independent contractor and
                nothing herein shall be construed as implying an employment,
                partnership, or joint venture relationship. The Provider is not
                an employee of the Client and is not entitled to any benefits
                that the Client may provide to its employees. Nothing herein
                shall be construed as empowering either party to act as a
                representative or agent of the other party. Neither party shall
                have the authority to enter into any contract, nor to assume any
                liability, on behalf of the other party, nor to bind or commit
                the other party in any manner, except as expressly provided in
                this Agreement.
            </p>
            <p>
                14.8 Force Majeure. Except as expressly provided otherwise in
                this Agreement, dates and times by which the Client or the
                Provider is required to perform under this Agreement, the Order
                Form, or a Schedule (except for any payment obligation) will be
                postponed automatically to the extent and for the period of time
                that the Client or the Provider, as the case may be, is
                prevented by causes outside of its reasonable control from
                meeting such dates and times by reason of any cause beyond its
                reasonable control (provided that a lack of financial resources
                shall not constitute an event beyond the reasonable control of a
                party). The following events are deemed to be outside of a
                party’s reasonable control: acts of God, acts of government,
                acts of war, civil or military unrest, acts of public enemies,
                epidemics, riots, fire, unavailability of communications or
                electrical power service provided by third parties, governmental
                regulations superimposed after the fact and earthquakes,
                explosions, floods or other disasters provided that such causes
                could not have been reasonably foreseen and the risk and/or
                consequences of such causes mitigated on a commercially
                reasonable basis. The parties agree that an event shall not be
                considered to beyond reasonable control if a reasonable business
                person applying due diligence in the same or similar
                circumstances under the same or similar obligations as the
                provisions of the Order Form or Schedule would have put in place
                contingency plans to either materially mitigate or negate the
                effects of such event. A party seeking to rely on this Section
                must (i) notify the other party immediately and in detail of the
                anticipated or actual commencement of and the cause of
                postponement; (ii) notify the other party promptly of any
                material changes in the circumstances which resulted in the
                postponement including when the reason for the postponement is
                at an end; and (iii) use diligent efforts to avoid or remove
                such cause of non-performance and to minimize the consequences
                thereof, including utilizing all resources reasonably required
                in the circumstances including without limitation obtaining
                supplies or services from other resources if they are reasonably
                available.
            </p>
            <p>
                14.9 Non-Solicitation. During the Term and for a period of one
                (1) year following termination of this Agreement for any reason,
                neither party may, directly or indirectly, (a) solicit for
                employment any employee or independent contractor of the other
                party who was materially involved in the performance of this
                Agreement; or (b) induce or attempt to induce any employee or
                independent contractor of the other party who was materially
                involved in the performance of this Agreement to leave his or
                her employ or contract, as applicable, with such other party.
                The foregoing will not prevent either party from hiring any
                employee or independent contractor who responds to a job posting
                or advertisement that is not specifically targeted at such
                employee or independent contractor.
            </p>
            <p>
                14.10 Currency. Unless otherwise specified in the Order Form,
                all references to amounts of money in this Agreement refer to
                United States of America (USD) currency.
            </p>
            <p>
                14.11 Notices. Notices that we give to you (other than notice of
                amendment of this Agreement), may be provided in any of the
                following ways. First, we may email the Administrator(s) at the
                contact information provided in the Order Form or any
                registration data. Second, we may post a notice on the Website.
                It is your responsibility to periodically review the Website for
                notices. The Client may provide notice to the Provider by (a)
                submitting a ticket through the helpdesk; or (b) e-mailing the
                Provider at
                <a href="mailto:support@bildhive.com;">support@bildhive.com</a>.
            </p>
            <p>
                14.12 Successors and Assigns. This Agreement shall ensure to the
                benefit of, and be binding on, the parties and their respective
                successors and permitted assigns. The Provider may assign this
                Agreement, the Order Form or any Schedule or any obligation
                hereunder, in its sole discretion. The Client may not assign
                this Agreement, the Order Form or any Schedule without the prior
                written consent of the Provider.
            </p>
            <p>
                14.13 Severability. Any provision of this Agreement which is
                prohibited or unenforceable in any jurisdiction will, as to that
                jurisdiction, be ineffective to the extent of such prohibition
                or unenforceability and will be severed from the balance of this
                Agreement, all without affecting the remaining provisions of
                this Agreement or affecting the validity or enforceability of
                such provision in any other jurisdiction.
            </p>
            <p>
                14.14 Entire Agreement. This Agreement, the Order Form and the
                Schedules constitute the entire agreement between the parties
                with respect to the subject matter of this Agreement and
                supersedes all previous negotiations, proposals, commitments,
                writings and understandings of any nature whatsoever.
            </p>
            <p>
                14.15 Waiver. No term or provision of this Agreement is deemed
                waived and no breach excused, unless the waiver or consent is in
                writing and signed by the party claiming to have waived or
                consented. Any consent by any party to, or waiver of, a breach
                by the other, whether expressed or implied, does not constitute
                a consent to, waiver of, or excuse for, any other different or
                subsequent breach.
            </p>
            <p>
                14.16 Fully Negotiated Agreement. The Client and the Provider
                acknowledge and agree that all of the provisions of this
                Agreement have been fully negotiated, that neither of them shall
                be deemed the drafter of this Agreement and that, in construing
                this Agreement in case of any claim that any provision hereof
                may be ambiguous, no such provision shall be construed in favour
                of one party on the ground that such provision was drafted by
                the other party.
            </p>
            <p>
                14.17 Language. The parties have required that this Agreement
                and all deeds, documents and notices relating to this Agreement
                be drawn up in the English language. Les parties aux présentes
                ont exigé que le présent contrat et tous autres contrats,
                documents ou avis afférents aux présentes soient rédigés en
                langue anglaise.
            </p>
            <p>
                14.18 Modification of Terms. The Provider may modify this
                Agreement at any time by (a) posting a notice on the Website or
                on the Software; or (b) by e-mailing the Administrator(s) of the
                Client. The Provider will also update the “Last Updated” date at
                the top of the Agreement. You are responsible for checking the
                Agreement whenever you access or use the Services. By continuing
                to access or use the Services, you are indicating that you agree
                to be bound by the modified terms. If the modified terms are not
                acceptable to you, you must stop accessing and using the
                Services. Notwithstanding the foregoing, the information and
                material on the Website, and the Website, may be changed,
                withdrawn or removed at any time in the Provider’s sole
                discretion without notice.
            </p>
            <p>
                14.19 Questions. If you have any questions regarding these Terms
                or your use of the Services, please contact us here:
            </p>
            <p>
                <strong>Bildhive Technologies Inc. </strong><br /><a
                    href="mailto:support@bildhive.com;"
                    >support@bildhive.com</a
                >
            </p>
        </div>
        <a-divider />
        <h5 style="text-align: center">
            <strong>PRIVACY POLICY </strong>
        </h5>
        <div>
            <p>
                Bildhive Technologies Inc. (<strong>“Bildhive”</strong>,
                <strong>“we”</strong>, <strong>“us”</strong>,
                <strong>“our”</strong> and terms of similar meaning) takes your
                privacy rights very seriously. We are committed to transparently
                describing our privacy practices, including how we collect, use
                and disclose (a) your personal or personally identifiable
                information (<strong>“Information”</strong>); and (b) data we
                obtain from your activity on the Services
                (<strong>“Data”</strong>) as part of providing the Bildhive
                website and/or and any web-platform, application, or software
                made available through the website (collectively, the
                <strong>“Platform”</strong>) and the related services made
                available through the Platform (the
                <strong>“Services”</strong>).
            </p>
            <p>
                We encourage you to read and understand our Terms and Conditions
                (<strong>“Terms”</strong>) and this privacy policy (<strong
                    >“Privacy Policy”</strong
                >) and any other agreement you agree to or enter into with
                Bildhive before using the Services. By accepting the Terms
                and/or Privacy Policy or by accessing the Services, you
                expressly consent to our collection, use and disclosure of your
                Information and Data in accordance with this Privacy Policy.
                This Privacy Policy is incorporated into and subject to our
                Terms.
            </p>
            <p>
                All capitalized terms that are not otherwise defined herein
                obtain their meaning from the Terms.
            </p>

            <p><strong>1. What Information Do We Collect? </strong></p>
            <p>
                (a) General. Our primary purpose in collecting Information and
                Data from you voluntarily is to provide you with a secure,
                efficient and customized experience when using the Services. We
                will only ask for and collect Information and Data that we
                consider necessary to ensure a positive experience.
            </p>
            <p>
                (b) Information and Data.
                <span>
                    <p>
                        (i) When signing up for a Client or User account,
                        Clients and Users of the Services must provide the
                        Information as requested or where indicated. On these
                        forms we indicate which fields are required and which
                        fields are optional where possible. Our primary purpose
                        in collecting Data from you voluntarily is to provide a
                        secure experience. When visiting the Website or using
                        the Services, we may collect, without limitation,
                        Information and Data including: your first and last
                        name, company name, email, address, phone number,
                        password for the Platform, Platform subscription plan
                        and other information inputted by you and Data generated
                        from your use of the Services.
                    </p>
                    <p>
                        (ii) As you use the Services, you can, or may be
                        required from time to time, to enter or send to us
                        Information, which may include, without limitation,
                        Information that you publish on your account as a Client
                        or User or Data that you upload to the Platform such as
                        attachments.
                    </p>
                    <p>
                        (iii) As you use the Services, you may voluntarily
                        provide Information to Bildhive’s sales, support or
                        training staff, which may include, without limitation,
                        your requests, questions and responses, information
                        provided in connection with surveys or research studies
                        that you chose to participate, information to verify
                        your identity, your date of birth, and your audio and
                        video if you participate in a sales call or user
                        research study and do not opt out of call recording,
                        which also involve the collection of biometric
                        information associated with such call recordings.
                    </p>
                    <p>
                        (iv) Bildhive may also collect your Information provided
                        as a result of your participation in Beta Services,
                        device information and activity when you use the
                        Platform via a mobile device, such as the type of device
                        you are using, device IDs, operating system version, and
                        mobile network information, which may include your
                        mobile phone number, and derived device geolocation
                        information, such as approximate geographic location
                        inferred from an IP address.
                    </p>
                    <p>
                        (v) Bildhive may also collect Information of another
                        individual or entity when you use the Services to
                        conduct a real estate transaction. In connection with a
                        real estate transaction, Bildhive may collect names,
                        addresses and email addresses of parties to a real
                        estate transaction, personal information about those
                        individuals or entities such as payment and banking
                        information, social security numbers, government issued
                        identifications, certain financial and/or employment
                        information (e.g.: credit score, letter of employment)
                        and history of actions that those individuals or
                        entities take on a real estate transaction (e.g. review,
                        and sign).
                    </p>
                    <p>
                        (vi) You represent that you have the right and authority
                        or have obtained all necessary consents and approvals to
                        provide any Information, including any Information of
                        another individual or entity, that is provided by you to
                        Bildhive through the Services or otherwise.
                    </p>
                </span>
            </p>
            <p>
                (c) Payment and Banking Information. When you add a credit/debit
                card, payment method or banking information to any Client
                account, these payment details will be shared with our third
                party payment processor(s). We do not store credit/debit card,
                payment method or banking information on our servers, unless
                provided in connection with a real estate transaction (see
                “Information and Data” above). If you modify which Administrator
                account provides payment for the Services, we will require that
                Information to be updated for your continued access to the
                Services.
            </p>
            <p>
                (d) Information and Data Collected Automatically.
                <span>
                    <p>
                        (i) When you use the Platform, Bildhive automatically
                        receives and records Data from your device, including
                        but not limited to your IP address, Data uploaded to the
                        Platform, other Platform IDs, emails and appointment
                        information, the page you requested, the timing,
                        frequency and pattern of your use of the Services,
                        metadata and inference information related to your use
                        of the Platform, the Website and Third-Party
                        integrations, and other Data resulting from your use of
                        Services. This usage data may be processed for the
                        purposes of analyzing the use of the Platform. Unless
                        otherwise stated in this Privacy Policy, Bildhive only
                        uses this Data in aggregate form.
                    </p>
                    <p>
                        (ii) “Cookies” and similar technologies are small files
                        placed on your computer and devices that assist us in
                        providing our Services. We and our third-party service
                        providers use cookies and similar technologies to
                        provide and personalize the Service, analyze use, target
                        advertisements and prevent fraud. You can disable
                        cookies in your browser settings, however, if you do so,
                        some parts of the Service may not function properly.
                    </p>
                </span>
            </p>
            <p>
                (e) Information and Data Collected from Third Parties. We may
                receive your Data from our use of services provided by
                subsidiaries, affiliates, third party service providers and
                third party integrations (collectively,
                <strong>“Third Parties”</strong>), including but not limited to
                Data or business contact information processed by Third-Parties
                such as Google, Microsoft 365, Microsoft Teams, Outlook, Zoom or
                DocuSign.
            </p>

            <p><strong>2. How Do We Use Your Information and Data? </strong></p>
            <p>
                We may use your Information to determine your eligibility to
                register for a Client or User account or to continue to have a
                Client or User account on the Platform. We may also use your
                Information and Data to provide to you the license for the
                Services; to provide you with the Services; to maintain and
                improve the Services, to communicate with you, either directly
                or through one of our partners, including for marketing and
                promotional purposes and to resolve your support requests; to
                provide you with reports about usage; to survey your opinions
                through surveys, research studies and questionnaires; to improve
                our marketing and promotional efforts; to advise of fee and
                Service updates; to facilitate transactions and payments (if and
                as applicable); to resolve service disputes; to provide webinars
                or public presentations, to provide you access to Beta Services
                or demos, to provide cross-device management of your Client or
                User account; to identify and fix bugs, and errors; to
                troubleshoot the Platform; to analyze usage of our Services; to
                improve our content and product offerings; to deliver
                information to you that, in some cases, is relevant to your
                interests; to customize your experience, the content, layout and
                services; to facilitate reporting and analyze performance of the
                Platform; to process your information at your direction; to
                detect and protect us against error, fraud and other criminal
                activity; to enforce our Terms; to comply with a valid legal
                request; to provide you with system or administrative messages;
                and/or as otherwise described to you at the time of collection.
                These uses improve the Services and better tailor it to meet
                your needs, so as to provide you with a secure, efficient and
                customized experience while using the Services.
            </p>
            <p>
                Unless otherwise prohibited by law, Bildhive may combine the
                information that we collect through your use of the Services
                with information that we receive from other sources, both online
                and offline, and use that combined information as set out above.
            </p>

            <p>
                <strong>3. Our Disclosure of Your Information and Data </strong>
            </p>
            <p>
                (a) General Disclosure. We may share your Information and Data
                with third parties only in the ways that are described in this
                Privacy Policy and in accordance with your consent. We will not
                share, sell or rent your Information or Data to third parties
                without your explicit consent. You acknowledge and agree that
                the Data you upload to the Platform may be viewable by other
                Users part of your Client Account, depending on the settings
                your Administrator has selected.
            </p>
            <p>
                (b) Anonymized Aggregated Data. We may aggregate and anonymize
                your Data and use and disclose it for a variety of purposes,
                including analytics, to improve our algorithms, measure service
                usage, publish summaries and develop new features. We will not
                display or otherwise disclose aggregated information where a
                Client or User can be identified. In addition, we do not display
                or otherwise disclose any Information. Furthermore, we may need
                to occasionally review raw data and the results for system
                maintenance. If this review is necessary, we will only see the
                unique identifier number with the data.
            </p>
            <p>
                (c) Subsidiaries, Affiliates & Service Providers. We may use the
                services of Third Parties in connection with our provision of
                the Services, including, without limitation, for the processing
                of payments, off-premise data hosting, communication services,
                account hosting, integrations, and Information collecting and
                analysis services. We may disclose your Information and Data to
                the Third Parties in the course of our use of their services. We
                take care to use Third Parties that we believe are reputable and
                capable of performing the services we require of them,
                including, without limitation, the handling of confidential
                information and Information and Data and the compliance with all
                applicable laws. This Privacy Policy does not apply to Data
                processed by Third Parties through your use of any Third-Party
                integrations available through the Platform. Please visit the
                Third-Party websites for more information regarding their
                privacy and data protection practices.
            </p>
            <p>
                (d) Google integrations. By using the ‘Gmail Integration’
                available in the Platform, you will grant the Platform with
                access to information associated with your account, including
                but not limited to emails, subject lines, URLs of tracked links
                from your email, email labels, email bodies, contacts and your
                calendar. In addition, the Platform will be able to read,
                compose, send and permanently delete all your emails from your
                connected Gmail account. You understand that any of the
                activities mentioned above will be also visible to other Users
                on your Client account. Notwithstanding anything else in this
                Privacy Policy, if you provide the Platform access to your
                Google data, Bildhive’s use of that data will be subject to the
                following additional restrictions: (i) Bildhive will only use
                access to read, write, modify or control Gmail message bodies
                (including attachments), metadata, headers, and settings to
                provide a web email client that allows users to compose, send,
                read, and process emails and to enhance the email experience for
                productivity purposes, (ii) Bildhive will not transfer your
                Gmail data to others unless doing so is necessary to provide and
                improve these features, comply with applicable law, or as part
                of a merger, acquisition, or sale of assets; (iii) Bildhive will
                not use this Gmail data for serving advertisements, (iv)
                Bildhive will not allow humans to read this data unless we have
                your affirmative consent for specific messages, doing so is
                necessary for security purposes such as investigating abuse, to
                comply with applicable law, or for Bildhive’s internal
                operations when the data has been aggregated and anonymized; and
                (v) Bildhive's use and transfer to any other application of
                information received from Google APIs will adhere to Google API
                Services User Data Policy.
            </p>
            <p>
                (e) Public Forums. Public forums, such as Bildhives’ social
                channels, allow you to upload and share comments or feedback
                publicly with other Users. Any information that you submit
                through such public forums is not confidential, and Bildhive may
                use it for any purpose (including in testimonials or other
                Bildhive marketing materials). Any information you post openly
                in these ways will be available to the public at large and
                potentially accessible through third-party search engines. Such
                information can be read, collected and/or used by other Users,
                and it could be used to send you unsolicited messages.
            </p>
            <p>
                (f) Laws and Jurisdictions. Notwithstanding anything to the
                contrary in this Privacy Policy, we may preserve or disclose
                your Information and Data if we believe that it is reasonably
                necessary to comply with a law, regulation or legal request; to
                protect the safety of any person; to address fraud, security or
                technical issues; or to protect our or any other person’s or
                entity’s rights or property. However, nothing in this Privacy
                Policy is intended to limit any legal defenses or objections
                that you may have to a third party’s, including a government’s,
                request to disclose your Information and Data.
            </p>
            <p>
                (g) Sale of Business. We may disclose Information and Data to
                the acquiror or its agents in the course of the sale of our
                business. If we do this, the disclosure will be subject to
                confidentiality arrangements customary in such transactions. In
                addition, if the ownership of all or substantially all of our
                business changes, or all or some of our assets are sold as part
                of a bankruptcy or other proceeding, we may transfer your
                information to the new owner so that the services can continue
                to operate. In such case, your information would remain subject
                to the commitments contained in this Privacy Policy until the
                acquiring party updates it. If such transfer is subject to
                additional mandatory restrictions under applicable laws or
                agreements, Bildhive will comply with those restrictions.
            </p>
            <p>
                (h) Bildhive Account Administrator. If you are using the
                Platform in connection with an organization, or company domain,
                your company’s account Administrator can export data associated
                with the domain they manage.
            </p>
            <p>
                (i) Storing and Processing Your Information and Data. In some
                cases, Information and Data that we collect may be stored or
                processed outside of the jurisdiction it is received. When that
                occurs, we continue to protect the Information and Data with
                appropriate safeguards and data export requirements, but it may
                be subject to the legal jurisdiction of those countries and
                governmental authorities in those countries.
            </p>

            <p><strong>4. Communication Preferences </strong></p>
            <p>
                You can always unsubscribe from our commercial and promotional
                emails, but we may still send you communications relating to
                your status as a Client and your Users and your use of the
                Services in accordance with applicable anti-spam legislation.
            </p>

            <p><strong>5. Data Retention and Other Information </strong></p>
            <p>
                (a) General Retention Policy. We will periodically de-identify
                (a) Information from collected Data in unused User Accounts; and
                (b) unnecessary Information from Data collected elsewhere on
                and/or through the Services.
            </p>
            <p>
                (b) Termination of Client or User Account. If your Client or
                User Accounts are terminated, we will deactivate them, but we
                may retain your Information and Data for a certain period of
                time, in accordance with applicable privacy laws, and disclose
                it in a manner consistent with our practices under this Privacy
                Policy.
            </p>
            <p>
                (c) Do-Not-Track. Do-Not-Track is a privacy preference that
                Clients and Users can set in certain web browsers. We are
                committed to providing you with meaningful choices about the
                information collected on our websites for Third Party purposes,
                and therefore, we provide you with the ability to opt out, if
                needed, and adjust your cookie preferences. We do not currently
                recognize or respond to browser-initiated Do-Not-Track signals.
            </p>
            <p>
                (d) Marketing Practices and Choices. You may instruct Bildhive
                not to use your contact information to contact you by email,
                postal mail, or phone regarding services, promotions, and
                special events that might appeal to your interests by contacting
                us at
                <a href="mailto:privacy@bildhive.com;">privacy@bildhive.com</a>.
                For commercial email messages, you can also opt out by following
                the instructions located at the bottom of such emails. Please
                note that, regardless of your request, we may still use and
                share certain information as permitted by this Privacy Policy or
                as required by applicable law. For example, you may not opt out
                of certain transactional, operational, or service-related
                emails, such as those reflecting our relationship or
                transactions with you.
            </p>
            <p>
                Other Important Information. If you are under the age of 18, you
                may not have a Bildhive account or use the Platform. We do not
                knowingly process any information from, or direct any of our
                products or services to anyone under the major age of consent in
                the jurisdiction of residence of the individual or determined
                based on the address of such individual.
            </p>

            <p><strong>6. Your Information and Data Rights </strong></p>
            <p>
                In accordance with the applicable privacy laws, you may have the
                following rights with respect to your Information and Data:
            </p>
            <p>
                (a) Right of Access and Portability. You may ask for an overview
                or copy of your Information and/or Data to be provided to you;
                and/or transferred to another organization.
            </p>
            <p>
                (b) Right to Rectification. You may review your Information and
                delete and/or update it through your User Account or by
                contacting us, to ensure it is accurate and complete on the
                Services.
            </p>
            <p>
                (c) Right to Erasure and Restriction. You may ask us to remove
                and/or restrict our processing of your Information and/or Data
                in certain circumstances (e.g. if you believe we have processed
                your Information and/or Data unlawfully, if you believe that
                your Information and/or Data is no longer necessary for the
                purposes in which it was collected or processed, you withdraw
                your consent (as discussed below). Notwithstanding the
                foregoing, despite such request, subject to all applicable laws
                and the terms and conditions of this Privacy Policy, we may
                still retain your Information and Data for legitimate business
                interests, to collect any fees owed (if and as applicable),
                resolve disputes, troubleshoot problems, analyze usage of the
                Services, assist with any investigations, prevent fraud, enforce
                our Terms and/or take other actions as required or permitted by
                law.
            </p>
            <p>
                (d) Right to Withdraw Consent. Where we have asked for your
                consent to use and/or process your Information and Data, you can
                withdraw this consent at any time. We will do our best to
                accommodate your request, subject to applicable laws and the
                terms and conditions of this Privacy Policy.
            </p>
            <p>
                Please contact us at
                <a href="mailto:privacy@bildhive.com;">privacy@bildhive.com</a>.
                if you would like to exercise any of the above rights. Upon your
                request, we will need to verify your identity and may need to
                verify your relationship with Bildhive for security and fraud
                prevention. Further, we may take additional steps to verify that
                you are and authorized Client or Administrator to make the
                request. If you are a User, you should direct requests relating
                to your information to the Administrator of the Client account.
            </p>
            <p><strong>7. Security </strong></p>
            <p>
                We strive to protect your Information and Data by putting in
                place a range of technical and organizational measures to
                safeguard and secure the Information and Data we receive from
                you, including without limitation, security technologies. We are
                continuously utilizing security measures to protect your
                Information and Data from unauthorized access or against loss,
                misuse or alteration. Despite our efforts, we cannot guarantee
                the security of your Information and Data. Unauthorized entry or
                use, hardware or application failure and other factors, may
                compromise the security of your Information and Data at any
                time. We reserve the right, without any limitation, to
                investigate any suspected breaches of the Services’ security or
                information technology or other systems or networks.
            </p>

            <p><strong>8. Changes to This Privacy Policy </strong></p>
            <p>
                Bildhive may amend this Privacy Policy from time to time. The
                use of Information and Data we collect is subject to the Privacy
                Policy in effect at the time the Information or Data is used. If
                we make any material changes in the way we use your Information
                or Data, we will notify you by (a) posting a notice on the
                Client and User account page of the Platform or elsewhere on the
                Services; or (b) e-mailing you at your e-mail address associated
                with your Client, Administrator or User account. Clients and
                Users of the Services are bound by any changes to the Privacy
                Policy when they use the Services after such changes have been
                first posted.
            </p>

            <p><strong>9. Questions? </strong></p>
            <p>
                It is our goal to make our privacy practices easy to understand.
                If you have questions, concerns or if you would like more
                detailed information, please email our data controller at
                <a href="mailto:privacy@bildhive.com;">privacy@bildhive.com</a>.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        redirectTo() {
            const tld = window.location.href.includes("bildhive.com")
                ? "com"
                : "dev";
            return `https://admin.bildhive.${tld}/system/privacy`;
        },
    },
};
</script>

<style lang="scss" scoped>
a {
    color: var(--primary);
}

table,
th,
td {
    border: 1px solid black;
}
</style>
