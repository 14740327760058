<template>
	<div :class="$style.auth" style="background-color: #f7f5f9">
		<div class="pt-5 pb-5 dF jC align-items-end mt-auto">
			<img src="@/assets/logo.svg" alt="Bildhive Logo" style="max-height: 40px" />
		</div>
		<div class="p-5 bg-white text-center" :class="$style.container">
			<div class="text-dark font-size-30">
				Create Your Bildhive Account
			</div>
			<div class="text-center font-size-16 mb-3 text-dark-gray">
				Already have a Bildhive Account?
				<router-link to="/system/login" style="color: var(--orange)" class="hover:text-primary text-underlined">
					<u>Sign In</u>
				</router-link>
			</div>
			<a-form-model ref="ruleForm" :model="register" :rules="rules" class="mb-4 mt-5">
				<a-row :gutter="16" style="text-align: left">
					<a-col :span="12">
						<a-form-model-item prop="firstName" required label="First Name">
							<a-input class="input-border-style" size="large" v-model="register.firstName" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item prop="lastName" required label="Last Name">
							<a-input class="input-border-style" size="large" v-model="register.lastName" />
						</a-form-model-item>
					</a-col>

					<a-col :span="24">
						<a-form-model-item has-feedback prop="email" required label="Email Address">
							<a-input class="input-border-style" size="large" v-model="register.email" type="email" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item prop="password" requried label="Password">
							<a-input-password class="password-input" size="large" v-model="register.password" />
						</a-form-model-item>
					</a-col>

					<a-col :span="24">
						<div v-show="register.password !== ''">
							<div v-if="getTotalPoints <= 1">
								<p style=" text-align: left; margin-bottom: 0px !important;"> Very weak </p>
								<div class="mb-3" style="width: 50px; border: 1px solid red"></div>
							</div>
							<div v-else-if="getTotalPoints == 2">
								<p style=" text-align: left; margin-bottom: 0px !important; "> Weak </p>
								<div class="mb-3" style="width: 100px; border: 1px solid red"></div>
							</div>
							<div v-else-if="getTotalPoints == 3">
								<p style=" text-align: left; margin-bottom: 0px !important; "> Medium </p>
								<div class="mb-3" style=" width: 150px; border: 1px solid orange; "></div>
							</div>
							<div v-else-if="getTotalPoints == 4">
								<p style=" text-align: left; margin-bottom: 0px !important; "> Good </p>
								<div class="mb-3" style="width: 200px; border: 1px solid blue"></div>
							</div>
							<div v-else>
								<p style=" text-align: left; margin-bottom: 0px !important; "> Strong </p>
								<div class="mb-3" style=" width: 250px; border: 1px solid green; "></div>
							</div>

							<h3 class="font-size-16 text-left">
								Create a password that:
							</h3>
							<ul class="text-left"
								:style="[getPassLength ? { 'padding-inline-start': '0px !important', } : { 'padding-inline-start': '22px !important', }]">
								<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
									<a-icon v-if="getFourteenCharacter &&
										register.password.length > 2
										" type="check" style="color: green; padding-right: 8px" />
									<a-icon v-else-if="getFourteenCharacter == false &&
										register.password.length > 2
										" type="close" style="color: red; padding-right: 8px" />contains at least 14 characters
								</li>
								<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
									<a-icon v-if="getLowerAndUpper &&
										register.password.length > 2
										" type="check" style="color: green; padding-right: 8px" />
									<a-icon v-else-if="getLowerAndUpper == false &&
										register.password.length > 2
										" type="close" style="color: red; padding-right: 8px" />contains both lower (a-z) and uppercase letters (A-Z)
								</li>
								<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
									<a-icon v-if="getNumber &&
										register.password.length > 2
										" type="check" style="color: green; padding-right: 8px" />
									<a-icon v-else-if="getNumber == false &&
										register.password.length > 2
										" type="close" style="color: red; padding-right: 8px" />contains at least one number (0-9)
								</li>
								<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
									<a-icon v-if="getSpecialCharacter &&
										register.password.length > 2
										" type="check" style="color: green; padding-right: 8px" />
									<a-icon v-else-if="getSpecialCharacter == false &&
										register.password.length > 2
										" type="close" style="color: red; padding-right: 8px" />contains at least one special character from @$!%*?&.
								</li>
								<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
									<a-icon v-if="getNameEmail &&
										register.password.length > 2
										" type="check" style="color: green; padding-right: 8px" />
									<a-icon v-else-if="getNameEmail == false &&
										register.password.length > 2
										" type="close" style="color: red; padding-right: 8px" />does not contain your name or email address
								</li>
							</ul>
						</div>
					</a-col>

					<a-col :span="24">
						<a-form-model-item prop="confirmPass" label="Confirm Password" required>
							<a-input class="input-style" size="large" v-model="register.confirmPass" type="password">
								<a-tooltip slot="suffix" overlayClassName="change-tooltip-color">
									<a-icon v-if="register.confirmPass ===
										register.password &&
										register.confirmPass.length > 0
										" type="check" style="color: green" />
								</a-tooltip>
							</a-input>
						</a-form-model-item>
					</a-col>

					<a-col :span="12">
						<a-form-model-item prop="role" label="Your Role" required>
							<a-input class="input-border-style" size="large" v-model="register.role" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item prop="businessNumber" label="Business Phone Number" required>
							<a-input class="input-border-style" size="large" v-model="register.businessNumber" />
						</a-form-model-item>
					</a-col>

					<a-col :span="12">
						<a-form-model-item prop="companyName" label="Company Name" required>
							<a-input class="input-border-style" size="large" v-model="register.companyName" />
						</a-form-model-item>
					</a-col>

					<a-col :span="12">
						<a-form-model-item prop="businessType" label="Type Of Business" required>
							<a-select class="dropdown-style" v-model="register.businessType">
								<a-select-option value="brokerage">Real Estate Brokerage</a-select-option>
								<a-select-option value="marketingAgency">Marketing Agency</a-select-option>
								<a-select-option value="builder">Builder</a-select-option>
								<a-select-option value="freelancer">Freelancer</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<a-col :span="24">
						<a-form-model-item prop="address" label="Address" required>
							<a-input class="input-border-style" size="large" v-model="register.address" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item prop="city" label="City" required>
							<a-input class="input-border-style" size="large" v-model="register.city" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item prop="region" label="State/Province" required>
							<a-input class="input-border-style" size="large" v-model="register.region" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item prop="country" label="Country" required>
							<a-select show-search size="large" class="dropdown-style" v-model="register.country">
								<a-select-option v-for="country in countries" :key="country.value" :value="country.name">
									{{ country.name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item prop="postal" label="Zip/Postal Code" required>
							<a-input class="input-border-style" size="large" v-model="register.postal" />
						</a-form-model-item>
					</a-col>

					<a-col class="mt-2 mb-3" :span="24">
						<div style="width: 21px; float: left; margin-right: 10px">
							<a-checkbox @change="onChange" :defaultChecked="register.receiveOffers" />
						</div>
						<div style="text-align: left; width: 90%; float: left">
							I agree to receive marketing communications and
							promotional offers from Bildhive.
						</div>
					</a-col>

					<button :disabled="loading" :loading="loading" type="submit" @click="validateFields"
						class="text-center btn btn-primary w-100 font-size-18">
						Create Your Account
					</button>

					<a-divider>OR</a-divider>
					<a :href="`https://api.bildhive.${tld}/connect/microsoft`">
						<a-button :disabled="loading" :loading="loading" size="large"
							class="text-center w-100 font-weight-semibold font-size-18">
							<img src="@/assets/microsoft.svg" style="width: 25px;" class="mr-3" />
							Register with Microsoft
						</a-button>
					</a>
					<a :href="`https://api.bildhive.${tld}/connect/google`">
						<a-button :disabled="loading" :loading="loading" size="large"
							class="text-center w-100 font-weight-semibold font-size-18 mt-3">
							<img src="@/assets/google.svg" style="width: 25px;" class="mr-3" />
							Register with Google
						</a-button>
					</a>
				</a-row>
			</a-form-model>
			<a-modal v-model="termsModal" :closable="false" :footer="null" :width="600" :centered="true">
				<div class="text-dark font-size-30" style="line-height: 1; text-align: center">
					Hi, Welcome to Bildhive
					<br />
					In order to complete your account registration please review
					our SAAS Terms and Conditions and Privacy Policy
				</div>
				<a-divider />
				<div style="max-height: 475px; overflow-y: scroll">
					<IAgree />
				</div>

				<button :disabled="loading" :loading="loading" class="mt-3 text-center btn btn-primary w-100 font-size-18"
					type="submit" @click="signUp">
					I Agree
				</button>
			</a-modal>
		</div>

		<footer-bh />
	</div>
</template>
<script>
import { $auth } from "bh-mod";
import Vue from "vue";
import FooterBh from "@/components/common/FooterTerms.vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6Ld3fcIdAAAAAH2DRp6NNsjmytHBjBRQb3i8q-Dm" });
import postalCodes from "postal-codes-js";
import data from "./data";
import IAgree from "./iagree.vue";

export default {
	components: { FooterBh, IAgree },
	name: "AirRegister",
	data: function () {
		return {
			tld: window.location.href.includes("bildhive.com") ? "com" : "dev",
			register: {
				email: "",
				firstName: "",
				lastName: "",
				companyName: "",
				password: "",
				confirmPass: "",
				receiveOffers: true,
				recaptchaKey: "",
				role: "",
				businessNumber: "",
				address: "",
				country: "",
				region: "",
				city: "",
				postal: "",
				businessType: "",
			},
			countries: data.countryList,
			termsModal: false,
			loading: false
		};
	},
	computed: {
		rules() {
			return {
				email: [{ validator: this.validateEmail, trigger: "change" }],
				postal: [
					{ validator: this.validatePostalCodes, trigger: "change" },
				],
				firstName: [
					{
						required: true,
						message: "Please enter your first name",
						trigger: "change",
					},
				],
				lastName: [
					{
						required: true,
						message: "Please enter your last name",
						trigger: "change",
					},
				],
				role: [
					{
						required: true,
						message: "Please enter your role",
						trigger: "change",
					},
				],
				businessNumber: [
					{
						required: true,
						message: "Please select your business number",
						trigger: "change",
					},
				],
				companyName: [
					{
						required: true,
						message: "Please enter your company name",
						trigger: "change",
					},
				],
				businessType: [
					{
						required: true,
						message: "Please enter your business type",
						trigger: "change",
					},
				],
				password: [
					{
						required: true,
						message: "Please enter a password",
						trigger: "change",
					},
				],
				confirmPass: [
					{
						required: true,
						message: "Please re-type your password",
						trigger: "change",
					},
				],
				address: [
					{
						required: true,
						message: "Please enter your address",
						trigger: "change",
					},
				],
				country: [
					{
						required: true,
						message: "Please select your country",
						trigger: "change",
					},
				],
				region: [
					{
						required: true,
						message: "Please enter your region",
						trigger: "change",
					},
				],
				city: [
					{
						required: true,
						message: "Please enter your city",
						trigger: "change",
					},
				],
			};
		},

		fingerPrint() {
			return this.$store.state.fingerPrint.id;
		},
		getPassLength() {
			let passLength = false;
			if (this.register.password.length > 2) {
				passLength = true;
			}
			return passLength;
		},
		getFourteenCharacter() {
			let fourteenCharacter = false;
			if (this.register.password.length >= 14) {
				fourteenCharacter = true;
			}
			return fourteenCharacter;
		},
		getLowerAndUpper() {
			let lowerAndUpper = false;
			if (
				this.register.password.match(/[a-z]+/) != null &&
				this.register.password.match(/[A-Z]+/) != null
			) {
				lowerAndUpper = true;
			}
			return lowerAndUpper;
		},
		getNumber() {
			let number = false;
			if (
				this.register.password.match(/[\d]+/) != null
			) {
				number = true;
			}
			return number;
		},
		getSpecialCharacter() {
			let specialCharacter = false;
			if (
				this.register.password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
			) {
				specialCharacter = true;
			}
			return specialCharacter;
		},
		getNameEmail() {
			let noNameEmail = true;
			if (
				this.register.firstName != "" &&
				this.register.lastName != "" &&
				this.register.email != ""
			) {
				if (
					this.register.password.match(new RegExp(this.register.firstName, "i")) != null ||
					this.register.password.match(new RegExp(this.register.lastName, "i")) != null ||
					this.register.password.match(new RegExp(this.register.email, "i")) != null
				) {
					noNameEmail = false;
				}
			}
			return noNameEmail;
		},
		getTotalPoints() {
			var points = 0;

			if (this.register.password.length >= 4) points++;
			if (
				this.register.password.match(/[a-z]/) &&
				this.register.password.match(/[A-Z]/)
			)
				points++;
			if (this.register.password.match(/\d+/)) points++;
			if (this.register.password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))
				points++;
			if (this.register.password.length >= 14) points++;
			if (this.register.password.length >= 16) points++;

			return points;
		},
	},
	methods: {
		validateEmail(rule, value, callback) {
			if (value === "") {
				callback(new Error("Please enter an email"));
			} else {
				const result =
					/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(
						value
					);
				if (result) {
					callback();
				} else {
					callback(new Error("That's not a valid email address."));
				}
			}
		},

		validatePostalCodes(rule, value, callback) {
			console.log('Running postal code validation...')
			if (value === "") {
				callback(new Error("Please enter a Zip/Postal Code"));
			} else {
				if (!this.register.country) {
					callback(new Error("Please select a country first"));
				}
				const countryCode = data.countries[this.register.country];
				const result = postalCodes.validate(countryCode, value);
				if (result === true) {
					callback();
				} else {
					callback(new Error(result));
				}
			}
		},
		registerModalOk() {
			this.$notification["success"]({
				message: "Registration Successful",
				description:
					"Thank you for registering. Please check your inbox to verify your email account. Also, remember to check your SPAM or Junk folders. For any assistance, feel free to contact us at support@bildhive.com.",
				duration: 5,
			});
			this.$router.push("/system/login");
		},
		showError(msg) {
			this.$notification["warning"]({
				message: "Oops",
				description: msg,
				duration: 3,
			});
		},
		validateFields() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					this.loading = true;
					await this.recaptcha();
					this.loading = false;
					let { password = "", confirmPass = "" } = this.register;
					if (password.length < 14)
						return this.showError(
							"Password must be atleast 14 characters in length"
						);
					if (
						password.match(/[0-9]+/) == null
					)
						return this.showError(
							"Password must contain a number"
						);
					if (
						this.register.password.match(
							/[-!$%^&*()@#_+|~=`{}\[\]:";'<>?,.\/]+/
						) == null
					)
						return this.showError(
							"Password must contain a special character from @$!%*?&."
						);
					if (password.match(/[a-z]+/) == null)
						return this.showError(
							"Password must contain a lowercase letter"
						);
					if (password.match(/[A-Z]+/) == null)
						return this.showError(
							"Password must contain an uppercase letter"
						);
					if (password !== confirmPass)
						return this.showError(
							"Confirm password does not match"
						);
					this.termsModal = true;
				} else {
					return false;
				}
			});
		},

		signUp() {
			console.log('Signing up...')
			this.loading = true;
			this.$nprogress.start();
			let {
				email = "",
				firstName = "",
				lastName = "",
				password = "",
				receiveOffers = true,
				recaptchaKey = "",
			} = this.register;
			let self = this;

			let url = "/auth/local/register"
			const agentToken = self.$route.params.token
			if (agentToken) {
				url = `/auth/local/register-agent/${agentToken}`
			}
			console.log('Before API Call')
			$auth
				.post(
					url,
					{
						email,
						firstName,
						lastName,
						password,
						username: firstName + lastName + email,
						receiveOffers,
						recaptchaKey,
						phone: self.register.businessNumber,
						company: self.register.companyName,
						industry: self.register.businessType,
						userRole: self.register.role,
						address: self.register.address,
						country: self.register.country,
						city: self.register.city,
						region: self.register.region,
						postal: self.register.postal,
					},
					{
						headers: {
							"x-fp": self.fingerPrint,
						},
					}
				)
				.then(() => {
					console.log('Registration Successful')
					self.loading = false;
					self.termsModal = false;
					self.registerModalOk();
					self.$nprogress.done();
				})
				.catch((err) => {
					console.log('An error occurred while signing up. Please try again.', err)
					self.loading = false;
					self.$nprogress.done();
					self.$message.error(self.$err(err, `An error occurred while signing up. Please try again.`))
				});
		},
		async recaptcha() {
			// (optional) Wait until recaptcha has been loaded.
			console.log('Before recaptchaLoaded')
			await this.$recaptchaLoaded();

			console.log('Before recaptcha login')
			// Execute reCAPTCHA with action "login".
			const token = await this.$recaptcha("login");

			console.log('recaptcha token received')
			// Do stuff with the received token.
			this.register.recaptchaKey = token;
		},
		onChange(e) {
			this.register.receiveOffers = e.target.checked;
		},
	},

	created() { },

	async mounted() {
		setTimeout(() => {
			this.$nextTick(() => {
				console.log('Before recaptchaInstance')
				const recaptcha = this.$recaptchaInstance;
				console.log('After recaptchaInstance')
				if (recaptcha) {
					// Hide reCAPTCHA badge:
					console.log('Before recaptcha hideBadge')
					recaptcha.hideBadge();
					console.log('After recaptcha hideBadge')
				}
			});
		}, 2000);
	},
};
</script>
<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>

<style scoped>
.redBorder {
	border: 1px solid var(--red) !important;
}

.input-border-style {
	border-bottom: 1.5px solid #e1dce5 !important;
	border-radius: 0;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
}

.input-border-style:focus {
	border-bottom: 1.5px solid #000 !important;
}
</style>
<style>
.dropdown-style .ant-select-selection {
	border-bottom: 1.5px solid #e1dce5;
	border-radius: 0;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
}

.dropdown-style .ant-select-selection {
	box-shadow: none;
}

.dropdown-style .ant-select-selection:focus {
	box-shadow: none;
	border-bottom: 1.5px solid #000 !important;
}

.dropdown-style .ant-select-selection:active {
	box-shadow: none;
}

.input-style .ant-input {
	border-bottom: 1.5px solid #e1dce5 !important;
	border-radius: 0;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
}

.input-style .ant-input:focus {
	border-bottom: 1.5px solid #000 !important;
}

.password-input .ant-input {
	border-bottom: 1.5px solid #e1dce5 !important;
	border-radius: 0;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
}

.password-input .ant-input:focus {
	border-color: #000 !important;
}

.ant-divider-horizontal {
	margin: 10px 0;
}

.ant-modal-body {
	padding: 15px;
}
</style>
